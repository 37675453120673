.nav {
  display: flex;
  justify-content: space-between;

  &__logo {
    width: 200px;
    height: 75.2px;
    padding-top: 5px;
  }

  &__list {
    display: flex;
    align-items: flex-end;

    &__item {
      padding: 0.5rem 1rem;

      a {
        color: $light-color;
        font-size: 22px;
        border-bottom: 3px transparent solid;
        padding-bottom: 0.3rem;
        transition: border-color 0.5s;

        &:hover {
          border-color: $primary-color;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .nav {
    position: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;

    &__list__item {
      padding: 10px;
    }
  }
}
