$website-width: 1440px;

$light-color: #f2f2f2;
$medium-color: #b9b9b9;
$dark-color: #333;
$primary-color: #48a2df;
$error-color: rgb(190, 68, 68);

$header-img: url('../images/header-bg.jpg');
$about-img: url('../images/dach-about.jpg');
