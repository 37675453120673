.offer {
  color: $dark-color;
  height: 100vh;
  background: rgb(20, 20, 20);
  background: linear-gradient(
    202deg,
    rgba(20, 20, 20, 1) 0%,
    rgba(26, 26, 40, 1) 19%,
    rgba(71, 71, 80, 1) 100%
  );

  .modal {
    visibility: hidden;
    &__content {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 960px;
      max-width: 100%;
      height: 0px;
      max-height: 100%;
      z-index: 11;
      transition: all 0.6s ease;
    }

    &--overlay {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      overflow: auto;
      z-index: 10;
      transition: all 0.6s ease;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;

    .title {
      font-size: 3rem;
    }
  }

  &__content {
    &__types {
      display: grid;
      grid-template-columns: repeat(4, minmax(300px, 1fr));
      grid-template: 'card1 card2 card3 card4';
      padding-top: 20px;

      .card {
        &__img {
          width: 100%;
          height: 225px;
          object-fit: cover;
          box-shadow: 4px 6px 10px rgba($color: #000000, $alpha: 0.4);
        }

        &__title {
          color: $light-color;
          text-align: center;
          font-size: 1.1rem;
        }
      }
    }

    &__example {
      padding: 35px 0px;
      display: flex;
      justify-content: center;
    }

    &__done {
      display: grid;
      grid-template-columns: repeat(4, minmax(300px, 1fr));
      grid-template: 'card1 card2 card3 card4';
      padding-top: 40px;

      .card {
        i {
          color: $primary-color;
          display: flex;
          justify-content: center;
        }

        &__desc {
          padding-top: 5px;
          color: $light-color;
          text-align: center;

          h2 {
            font-size: 2.6rem;
          }

          p {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) {
  .offer {
    height: 100%;
    &__content {
      max-width: 80%;
      margin: auto;

      &__types {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
        grid-template:
          'card1 card2'
          'card3 card4';
        .card {
          padding-bottom: 30px;
        }
      }

      &__done {
        grid-template-columns: repeat(2, minmax(300px, 1fr));
        grid-template:
          'card1 card2'
          'card3 card4';
        padding-bottom: 20px;
        .card {
          padding-bottom: 40px;
        }
      }

      &__example {
        padding: 20px 0px;
      }
    }
  }
}

@media (max-width: 900px) {
  .offer {
    .slider__slide__desc {
      max-width: 300px;
    }

    .modal__content {
      max-height: 50%;
    }
    &__content {
      max-width: 100%;
    }
  }
}

@media (max-width: 700px) {
  .offer__content {
    &__types {
      grid-template-columns: 1fr;
      grid-template:
        'card1'
        'card2'
        'card3'
        'card4';
    }

    &__done {
      grid-template-columns: 1fr;
      grid-template:
        'card1'
        'card2'
        'card3'
        'card4';
    }
  }
}

.card {
  width: 300px;
  height: 100%;
  padding-bottom: 15px;
  justify-self: center;

  &:nth-child(1) {
    grid-area: card1;
  }
  &:nth-child(2) {
    grid-area: card2;
  }
  &:nth-child(3) {
    grid-area: card3;
  }
  &:nth-child(4) {
    grid-area: card4;
  }
}
