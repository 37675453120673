.btn {
  display: inline-block;
  border: 1px solid transparent;
  padding: 0.8rem 2rem;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  font-weight: 600;
  text-align: center;

  &--light {
    border-color: $light-color;
    color: $light-color;

    &:hover {
      background-color: $light-color;
      color: $dark-color;
    }
  }

  &--primary {
    border-color: $light-color;
    color: $light-color;
    background-color: transparent;

    &:hover {
      border-color: $primary-color;
      color: $primary-color;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
