.about {
  background: $about-img no-repeat center center / cover;
  height: 512px;

  .overlay {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0.1) 50%,
      rgba(0, 0, 0, 0.1) 100%
    );
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__content {
    display: flex;
    flex-direction: column;
    color: $light-color;
    max-width: 40%;
    height: 100%;
    margin-left: 90px;

    .title {
      margin: 45px 0px 20px 0px;
    }

    &__desc {
      padding-top: 15px;
      p {
        font-size: 1.2rem;
        line-height: 140%;
        span {
          color: $primary-color;
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .about {
    &__content {
      margin-left: 45px;
    }
  }
}

@media (max-width: 910px) {
  .about {
    .overlay {
      background: rgb(0, 0, 0, 0.5);
    }

    &__content {
      max-width: 100%;
      margin-left: 0px;
      align-items: center;

      &__desc {
        max-width: 60%;
        padding-top: 5px;
      }
    }
  }
}

@media (max-width: 700px) {
  .about__content__desc {
    max-width: 80%;
  }
}

@media (max-width: 500px) {
  .about__content__desc {
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .about__content__desc {
    p {
      font-size: 1.1rem;
    }
  }
}

@media (max-width: 361px) {
  .about__content__desc {
    p {
      font-size: 1rem;
    }
  }
}
