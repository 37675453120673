.header {
  background: $header-img no-repeat fixed center bottom / cover;
  height: 100vh;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }

  &__content {
    text-align: center;
    position: relative;
    top: 27%;

    &__title {
      color: $light-color;
      font-size: 70px;
      span {
        color: $primary-color;
      }
    }

    p {
      padding: 30px;
      color: $light-color;
      font-size: 32px;
    }
  }
}

@media (max-width: 600px) {
  .header {
    &__content {
      top: 17%;

      &__title {
        display: none;
      }
    }

    .container {
      max-width: 600px;
    }
  }
}
