.slider {
  margin: auto;
  height: 100%;
  width: 100%;

  position: relative;
  overflow: hidden;

  &__dots {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 3;

    &__dot {
      width: 0.7rem;
      height: 0.7rem;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
      background-color: $medium-color;
      transition: background-color 0.7s;
      margin: 0px 3px;

      &:hover {
        background-color: $light-color;
      }

      &--active {
        background-color: $light-color;
      }
    }
  }

  &__btn--exit {
    position: absolute;
    color: $light-color;
    right: 0px;
    top: 0px;
    z-index: 3;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.3rem;
    padding: 2px 11px 6px 11px;
    background-color: rgba(0, 0, 0, 0.5);
    transition: background-color 0.7s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  &__btn {
    position: absolute;
    z-index: 3;
    top: 50%;
    margin-top: -24px;
    font-size: 1.2rem;
    font-weight: bold;
    color: $light-color;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    outline: none;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 0.7s;

    &--prev {
      left: 0px;
    }

    &--next {
      right: 0px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  &__slide {
    position: absolute;
    height: 100%;
    width: 100%;
    transition: transform 0.8s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__desc {
      position: absolute;
      bottom: 40px;
      left: 40px;
      background-color: rgba(0, 0, 0, 0.7);
      max-width: 400px;
      color: $light-color;

      &__title {
        margin: 1rem 1rem 0rem 1rem;
        padding: 3px 0px 0px 10px;
        border-left: 4px solid $primary-color;
        font-weight: 400;
        font-size: 1.3rem;
      }

      &__text {
        padding: 1rem;
        font-size: 1rem;
        line-height: 140%;
      }
    }
  }
}
