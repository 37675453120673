body {
  font-family: 'Open Sans', sans-serif;
}

.container {
  max-width: $website-width;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden;
  height: 100%;
}

.title {
  font-size: 2.7rem;
  color: $light-color;
  padding-left: 10px;
  display: inline-block;
  border-left: 5px solid $primary-color;
}

.subtitle {
  display: inline-block;
  border-left: 4px solid $primary-color;
  padding-left: 8px;
  padding-bottom: 2px;
  color: $light-color;
  font-size: 1.7rem;
}
